<template>
  <section>
    
    <div class="columns is-marginless">
      <div class="column is-12">    
          <div class="columns is-gapless is-marginless is-centered is-mobile">
            <div class="column is-narrow is-mobile">
              <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >companies</div>
            </div>
          </div>
      </div>
    </div>
    
    <div class="columns is-marginless">
      <div class="column is-12">    
          <div class="columns is-gapless is-marginless is-centered is-mobile">
            <b-field label="Company" class="column is-4">
                <b-select placeholder="Select" v-model="selectedCompany">
                    <option
                        v-for="company in companyData"
                        :value="company.companies_id"
                        :key="company.companies_id">
                        {{ company.companies_id }} - {{ company.companies_name }} {{ company.companies_prefix }}
                    </option>
                </b-select>
            </b-field>
            
            <b-field label="From" class="column is-2">
              <b-datetimepicker
                  v-model="fromDate"
                  rounded
                  placeholder="Click to select..."
                  icon="calendar-today"
                  :icon-right="fromDate ? 'close-circle' : ''"
                  :locale="locale"
                  :first-day-of-week="firstDayOfWeek"
                  :datepicker="{ showWeekNumber }"
                  :timepicker="{ enableSeconds, hourFormat }"
                  horizontal-time-picker
                  trap-focus>
              </b-datetimepicker>
            </b-field>
            
            <b-field label="Till" class="column is-2" style="margin-left:15px">
              <b-datetimepicker
                  v-model="tillDate"
                  rounded
                  placeholder="Click to select..."
                  icon="calendar-today"
                  :icon-right="tillDate ? 'close-circle' : ''"
                  :locale="locale"
                  :first-day-of-week="firstDayOfWeek"
                  :datepicker="{ showWeekNumber }"
                  :timepicker="{ enableSeconds, hourFormat }"
                  horizontal-time-picker
                  trap-focus>
              </b-datetimepicker>
            </b-field>
            
            <b-field label="-" class="column is-1" style="margin-left:15px">
              <b-button class="button is-primary" @click="LoadCompanyData">
                <strong>Load</strong>
              </b-button>
            </b-field>
         </div>
      </div>           
    </div>
      
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem">
      <b-table
          :data="accountOverviewMain"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="tableIsLoading"
          :mobile-cards="true">

          <b-table-column field="companyId" label="ID" numeric v-slot="props" centered >
              <a :href='`${WEB_ROOT}/company/${props.row.companyId}`' target='_blank'>{{ props.row.companyId }}</a>
          </b-table-column>
          <b-table-column field="companyName" label="name" v-slot="props" centered>
              {{ props.row.companyName }}
          </b-table-column>
          <b-table-column field="companyPrefix" label="prefix" v-slot="props" centered>
              {{ props.row.companyPrefix }}
          </b-table-column>
          <b-table-column field="departments" label="departments" numeric v-slot="props" centered >
              {{ props.row.departments }}
          </b-table-column>
          <b-table-column field="companyUrl" label="companyUrl" v-slot="props" centered>
              <a :href='`${ props.row.companyUrl }`' target='_blank'> WA </a>
          </b-table-column>
          <b-table-column field="paidAdminAccount" label="paidAdminAccount" numeric v-slot="props" centered>
            {{ props.row.paidAdminAccount }}
          </b-table-column>
          <b-table-column field="paidUserAccount" label="paidUserAccount" numeric v-slot="props" centered>
            {{ props.row.paidUserAccount }}
          </b-table-column>
          <b-table-column field="smsActive" label="smsActive" numeric v-slot="props" centered>
            {{ props.row.smsActive }}
          </b-table-column>
          <b-table-column label="roles" numeric v-slot="props" centered>
            <div v-for="(role, key) in props.row.roleOverviewList" v-bind:key="`role_${key}_index`" style='color: red;margin:5px;'>
            {{ role.name }} - {{ role.count }}
          </div>
          </b-table-column>


          
          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>
    
    <div v-if="accountOverviewUsers.length > 0" class="is-mobile is-gapless is-centered is-multiline m2rem">
      <b-table
          :data="accountOverviewUsers"
          :bordered="true"
          :striped="true"
          :narrowed="true"
          :loading="tableIsLoading"
          :mobile-cards="true">

          <b-table-column field="users_id" label="user id" numeric v-slot="props" centered searchable sortable>
            {{ props.row.users_id }}
          </b-table-column>
          <b-table-column field="company_user_id" label="company user id" numeric v-slot="props" centered searchable sortable>
            {{ props.row.company_user_id }}
          </b-table-column>
          <b-table-column field="users_name" label="users_name" v-slot="props" centered searchable sortable>
            {{ props.row.users_name }}
          </b-table-column>
          <b-table-column field="users_email" label="users_email" v-slot="props" centered searchable sortable>
            {{ props.row.users_email }}
          </b-table-column>
          
          <b-table-column field="created_at" label="created_at" v-slot="props" centered searchable sortable>
            user: {{ props.row.users_created_at ? DDMMYYYY_HHii(new Date(props.row.users_created_at)) : '' }}<br>
            companyUser: {{ props.row.company_user_created_at ?  DDMMYYYY_HHii(new Date(props.row.company_user_created_at)) : '' }}<br>
            {{ props.row.company_user_archived_at ? `archived: ${DDMMYYYY_HHii(new Date(props.row.company_user_archived_at))}` : '' }}<br>
            {{ props.row.users_delete_requested_at ? `deleted: ${DDMMYYYY_HHii(new Date(props.row.users_delete_requested_at))}` : '' }}<br>
          </b-table-column>
          
          <b-table-column field="isWAAdminInt" label="wa admin" v-slot="props" centered searchable sortable>
            {{ props.row.isWAAdminInt }}
          </b-table-column>
          <b-table-column field="isWAEmailInt" label="wa email" v-slot="props" centered searchable sortable>
            {{ props.row.isWAEmailInt }}
          </b-table-column>
          <b-table-column field="isAccountActiveInt" label="active" v-slot="props" centered searchable sortable>
            {{ props.row.isAccountActiveInt }}
          </b-table-column>          
          <b-table-column field="isBeheerderAccountInt" label="Beheerder Account" boolean v-slot="props" centered searchable sortable>
            {{ props.row.isBeheerderAccountInt }}
          </b-table-column>
          <b-table-column field="freeRolesInt" label="free Roles" v-slot="props" centered searchable sortable>
            {{ props.row.freeRolesInt }}
          </b-table-column>
          <b-table-column field="notFreeRolesInt" label="not Free Roles" v-slot="props" centered searchable sortable>
            {{ props.row.notFreeRolesInt }}
          </b-table-column>          
          <b-table-column field="isPaidBeheerderAccountInt" label="Paid Beheerder Account" v-slot="props" centered searchable sortable>
            {{ props.row.isPaidBeheerderAccountInt }}
          </b-table-column>
          <b-table-column field="isPaidUserAccountInt" label="Paid User Account" v-slot="props" centered searchable sortable>
            {{ props.row.isPaidUserAccountInt }}
          </b-table-column>
          <b-table-column field="shiftResponseCount" label="shift Response Count" v-slot="props" centered searchable sortable>
            {{ props.row.shiftResponseCount }}
          </b-table-column>
          
          <b-table-column field="roles" label="roles" v-slot="props" centered searchable sortable>
            {{ props.row.roles }}
          </b-table-column>
          <b-table-column field="rolesBeheerder" label="beheerder" v-slot="props" centered searchable sortable>
            {{ props.row.rolesBeheerder }}
          </b-table-column>
          <b-table-column field="rolesArchived" label="archived" v-slot="props" centered searchable sortable>
            {{ props.row.rolesArchived }}
          </b-table-column>
          <b-table-column field="rolesFree" label="roles Free" v-slot="props" centered searchable sortable>
            {{ props.row.rolesFree }}
          </b-table-column>
          <b-table-column field="rolesNotFree" label="roles Not Free" v-slot="props" centered searchable sortable>
            {{ props.row.rolesNotFree }}
          </b-table-column>
          <!-- <b-table-column field="rolesUnknown" label="roles Unknown" v-slot="props" centered searchable sortable>
            {{ props.row.rolesUnknown }}
          </b-table-column> -->
          <b-table-column  label="row" centered searchable sortable>
            row item
          </b-table-column>
         


          
          <template #empty>
            <div class="has-text-centered">No records</div>
          </template>

      </b-table>
    </div>
    
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii } from '@/helpers/dates.js';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

  export default {
    data() {
      return {
        companyData         : [],
        
        accountOverviewMain : [],
        accountOverviewUsers : [],
        
        
        selectedCompany     : 1,
        fromDate            : new Date('2023-01-01 00:00:00'),
        tillDate            : new Date('2025-01-01 00:00:00'),
        
        showWeekNumber: false,
        enableSeconds: false,
        hourFormat: '24', // Browser locale
        locale: 'nl-NL', // Browser locale
        firstDayOfWeek: 1, // 0 - Sunday
        
        
        tableIsLoading      : false,
        WEB_ROOT: WEB_ROOT,
        API_ROOT: API_ROOT,
        SITE_ROOT     : process.env.VUE_APP_SITE_MAIN,
        
        pageLoading         : false,
        pageLoadingTimeout  : null,
      }
    },
    async mounted() {
      this.ShowPageLoading(15000)
      this.getCompanyData()
    },
    methods: {
      DDMMYYYY_HHii,
      async getCompanyData() {
        let response = await axios_api.get(`${API_ROOT}/schedule/companies`, {});
        if (response.data.result !== 1) {
          this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
          this.HidePageLoading()
          return;
        }
        // console.info(`response:`, response)
        this.companyData = response.data.payload
        // console.info(`this.companyData:`, this.companyData)
        this.HidePageLoading()
      },
      
      /* eslint-disable-next-line */        
      dateThAttrs(column) {
        return null
      },
              
      /* eslint-disable-next-line */
      columnTdAttrs(row, column) {
        return null
      },
      
      boolToInt(val) {
        if (val === true) return 1
        if (val === false) return 0
        return val
      },
      
      async ShowPageLoading(maxDuration = 30000){
        this.pageLoading = true;
        
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
        
        this.pageLoadingTimeout = setTimeout(() => {
          this.HidePageLoading();
        }, maxDuration);
      },
      
      async HidePageLoading() {
        this.pageLoading = false;
        if (this.pageLoadingTimeout !== null) {
          clearTimeout(this.pageLoadingTimeout);
        }
      },
      
      async LoadCompanyData(){
        console.info(`selectedCompany:`, this.selectedCompany)
        console.info(`selectedCompany:`, this.selectedCompany)
        if (!this.selectedCompany || parseInt(this.selectedCompany) <= 0) return
        this.accountOverviewMain = []
        this.accountOverviewUsers = []
        
        // console.info(`fromDate:`, this.fromDate.getTime())
        // console.info(`tillDate:`, this.tillDate.getTime())
        
        let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.selectedCompany}/from/${this.fromDate.getTime()}/till/${this.tillDate.getTime()}/overview`, {});
        
        if (response.data.result !== 1) {
          this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
          this.HidePageLoading()
          return;
        }
        // console.info(`response:`, response)
        this.accountOverviewMain = response.data.payload.companyDetails
        console.info(`this.accountOverviewMain:`, this.accountOverviewMain)
        this.accountOverviewUsers = response.data.payload.users        
        console.info(`this.accountOverviewUsers:`, this.accountOverviewUsers)
        this.HidePageLoading()
        
      },
    }
  }
</script>

<style scoped>
.highlight{
  color: #0000cc;
  text-decoration: underline;
}

/* .isAvtive{
  color: green
} */
.notActive{
  color: red
}

.smallLetters{
  font-size: 12px;
  margin-bottom: 15px;;
}

.menuUrls a:hover{
  text-decoration: underline;
}

</style>